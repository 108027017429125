import "./App.css";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { darkTheme, lightTheme } from "./Utils/Theme";
import ArenaSection from "./Components/Sections/ArenaSection";
import NexaSection from "./Components/Sections/NexaSection";
import Home from "./Pages/Home";
import Privacy from "./Components/Privacy";
import Services from "./Components/Sections/Services";
import Error from "./Pages/Error";
import RootLayout from "./Pages/RootLayout";
import ReactGA from "react-ga";
import TrueValue from "./Components/Sections/TrueValue";
import CarPage from "./Pages/CarPage";
import DrivingSchool from "./Components/Sections/DrivingSchool";
import ChatBot from "./Components/ChatBot";
import About from "./Pages/About";
import Location from "./Components/Location";
const TRACKING_ID = "UA-256423940-1";
ReactGA.initialize(TRACKING_ID);

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const location = useLocation();
  const [nav2, setNav2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const bookNowRef = useRef(null);

  // ? *********************************** data before render all the pages ************************************ */
  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location]);

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <ChatBot>
        <RootLayout nav2={nav2} bookNowRef={bookNowRef}>
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  setDark={setIsDarkMode}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                />
              }
            />
            <Route path="/services" element={<Services />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/about-us" element={<About />} />
            <Route
              path="/arena"
              element={<ArenaSection bookNowRef={bookNowRef} />}
            />
            <Route path="/true-value" element={<TrueValue />} />
            <Route path="/loc" element={<Location />} />
            <Route
              path="/nexa"
              element={<NexaSection bookNowRef={bookNowRef} />}
            />
            <Route path="/driving-school" element={<DrivingSchool />} />
            <Route
              path="/fronx"
              element={<CarPage nav2={nav2} setNav2={setNav2} />}
            />
            <Route path="*" element={<Error />} />
          </Routes>
        </RootLayout>
      </ChatBot>
    </ThemeProvider>
  );
}

export default App;
